import {apiInstance} from './api';

export const login = (payload) => {
  return apiInstance.post('/user/auth/', payload);
};

export const logout = () => {
  return apiInstance.post('/user/logout/');
};

export const get_current_user = () => {
  return apiInstance.get('/user/me/');
};

export const change_password = (payload) => {
  return apiInstance.post('/user/change_password/', payload);
};
