import {createHashHistory} from 'history';
// import {createBrowserHistory} from 'history';

export const history = createHashHistory();
// export const history = createBrowserHistory();

history.listen((location, action) => {
  if (action === 'PUSH') {
    window.scrollTo(0, 0);
  }
});
