import {
  USER_LOGIN,
  USER_LOGOUT,
  SET_CURRENT_USER,
  USER_TOGGLE_LOGOUT_MODAL, SET_DAYEND_DATE, SET_TOKEN, TOGGLE_NOTICE_MODAL,
} from "./types";

import {history} from "../history";
import * as user_service from '../services/user_service';
import isElectron from "is-electron";
import {apiInstance} from '../services/api';

export const userLogin = (user) => dispatch => {
  if(isElectron()) {
    window.ipcRenderer.send('show-menu', user);
  }

  dispatch({type: USER_LOGIN, payload: user})
};

export const toggleLogoutModal = (exitApp) => dispatch => {
  dispatch({type: USER_TOGGLE_LOGOUT_MODAL, payload: exitApp})
};

export const user_logout = () => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({type: USER_LOGOUT});
    user_service.logout().then(res => {
      delete apiInstance.defaults.headers['Authorization'];
      localStorage.removeItem('token');
      history.push('/login');
      resolve(res);
    }).catch(err => {
      delete apiInstance.defaults.headers['Authorization'];
      localStorage.removeItem('token');
      // reject(err);
      history.push('/login');
    });
  });
};

export const set_current_user = (user) => ({
  type: SET_CURRENT_USER,
  payload: user
});

export const set_token = (token) => ({
  type: SET_TOKEN,
  payload: token
});

export const toggle_notice_modal = () => ({
  type: TOGGLE_NOTICE_MODAL,
  payload: {}
});
