import axios from "axios";

const apiInstance = axios.create({
  // baseURL: config.API_BASE_URL,
  // timeout: 1000
});

export {
  apiInstance
}
