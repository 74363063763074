import React from 'react';
import './Spinner.css';

const Spinner = (props) => {
  let cssClass = 'spinner';
  let style = {};

  if (props.color === 'white') {
    cssClass += ' spinner-white';
  } else if(props.color === 'jbs') {
    cssClass += ' spinner-jbs';
  } else {
    cssClass += ' spinner-blue';
  }

  if (props.size === 'xs') {
    cssClass += ' spinner-xs';
  } else if (props.size === 'sm') {
    cssClass += ' spinner-sm';
  } else {
    cssClass += ' spinner-md';
  }

  if (props.overlay) {
    cssClass += ' spinner-overlay';
  }

  if (props.inline) {
    style.display = "inline-block";
  } else {
    style.display = "block";
  }

  return (
    <span className={cssClass} style={style}/>
  )
};

export default Spinner;