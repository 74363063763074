import React from 'react';
import './OverlaySpinner.css';
import Spinner from "../spinner/Spinner";

const OverlaySpinner = (props) => {
  return (
    <div className="overlay-spinner">
      <div><Spinner inline/> {props.message ? props.message : 'Loading'}... </div>
    </div>
  )
};

export default OverlaySpinner;
