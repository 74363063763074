import {
  HEADER_HIDE_SIDEBAR,
  HEADER_SET_MENU,
  HEADER_TOGGLE_FULL_SCREEN
} from "../actions/types";

const initialState = {
  slug: "dashboard",
  isFullScreen: false,
  hide_sidebar: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case HEADER_SET_MENU:
      return {
        ...state,
        slug: action.payload
      };
    case HEADER_TOGGLE_FULL_SCREEN:
      return {
        ...state,
        isFullScreen: !state.isFullScreen
      };
    case HEADER_HIDE_SIDEBAR:
      return {
        ...state,
        hide_sidebar: !state.hide_sidebar
      };
    default:
      return state
  }
}
