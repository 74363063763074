import React, {Component} from 'react';
import {connect} from "react-redux";
import * as helper from "../../utils/helper";
import * as constants from '../../utils/constants';
import {
  Card,
  Row,
  Col,
  Button,
  Alert,
  Spin,
  Table,
  Typography,
  Pagination, Modal,
  Form,
  Input,
  Collapse,
  Select, Checkbox
} from 'antd';

import {CaretRightOutlined, SearchOutlined, ReloadOutlined, FileExcelOutlined, PlusOutlined} from "@ant-design/icons";
import * as report_service from "../../services/report_service";

class TrialBalanceReport3 extends Component {
  is_mounted = false;

  state = {
    is_report_loading: false,

    office_list: [],
    office_id: undefined,

    from_date: "",
    to_date: "",

    opening_dr_total: "",
    opening_cr_total: "",
    current_dr_total: "",
    current_cr_total: "",
    closing_dr_total: "",
    closing_cr_total: "",
    rows: [],
    is_exporting_excel: false,
    show_zero_balance: false,
    show_data: false,
  };

  componentDidMount() {
    this.is_mounted = true;

    window.scrollTo(0, 0);

    let office_list = [];
    office_list = office_list.concat(this.props.head_office_list);
    office_list = office_list.concat(this.props.mart_office_list);

    this.setState({
      from_date: helper.getFirstDayOfMonth(this.props.day_closure_bs_date),
      to_date: this.props.day_closure_bs_date,
      office_list: office_list,
      office_id: this.props.office_category_id === constants.OFFICE_CATEGORY_MART_OFFICE ? this.props.staff.office_id : undefined
    });

    // this.setState({
    //   from_date: '2077/04/01',
    //   to_date: '2078/03/31',
    // })
  };

  componentWillUnmount() {
    this.is_mounted = false;
  }

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  fetch_report = () => {
    this.setState({is_report_loading: true});
    const {office_id, from_date, to_date, show_zero_balance} = this.state;
    report_service.get_trial_balance_report(undefined, office_id, from_date, to_date, show_zero_balance).then(res => {
      if (this.is_mounted) {
        const data = res.data;

        let opening_dr_total = 0;
        let opening_cr_total = 0;
        let current_dr_total = 0;
        let current_cr_total = 0;
        let closing_dr_total = 0;
        let closing_cr_total = 0;


        let rows = [];
        data.forEach((obj, i) => {
          opening_dr_total += obj.opening_dr_amount;
          opening_cr_total += obj.opening_cr_amount;

          current_dr_total += obj.current_dr_amount;
          current_cr_total += obj.current_cr_amount;

          closing_dr_total += obj.closing_dr_amount;
          closing_cr_total += obj.closing_cr_amount;

          let row = {...obj, key: i};
          rows.push(row);
        });

        // console.log(opening_dr_total);
        // console.log(opening_cr_total);
        // console.log(opening_dr_total - opening_cr_total);

        // console.log('')
        //
        // console.log(current_dr_total);
        // console.log(current_cr_total);
        // console.log(current_dr_total - current_cr_total);

        opening_dr_total = parseFloat(parseFloat(opening_dr_total).toFixed(2));
        opening_cr_total = parseFloat(parseFloat(opening_cr_total).toFixed(2));

        current_dr_total = parseFloat(parseFloat(current_dr_total).toFixed(2));
        current_cr_total = parseFloat(parseFloat(current_cr_total).toFixed(2));

        closing_dr_total = parseFloat(parseFloat(closing_dr_total).toFixed(2));
        closing_cr_total = parseFloat(parseFloat(closing_cr_total).toFixed(2));

        this.setState({
          is_report_loading: false,
          show_data: true,
          rows: rows,
          opening_dr_total: opening_dr_total,
          opening_cr_total: opening_cr_total,
          current_dr_total: current_dr_total,
          current_cr_total: current_cr_total,
          closing_dr_total: closing_dr_total,
          closing_cr_total: closing_cr_total,
        });
      }
    }).catch(err => {
      if (this.is_mounted) {
        this.setState({is_report_loading: false});

        let message = helper.handle_response_error(err);
        Modal.error({centered: true, content: message});
      }
    });
  };

  export_report_excel = () => {
    const {office_id, from_date, to_date} = this.state;

    this.setState({is_exporting_excel: true});
    report_service.get_trial_balance_report('excel', office_id, from_date, to_date).then(res => {
      if (this.is_mounted) {
        const data = res.data;

        this.setState({
          is_exporting_excel: false,
        });

        let file_name = `Trial Balance ${from_date} ${to_date}.xlsx`;

        helper.downloadFile(data, file_name);
      }
    }).catch(err => {
      if (this.is_mounted) {
        this.setState({is_exporting_excel: false});

        let message = helper.handle_response_error(err);
        Modal.error({centered: true, content: message});
      }
    });
  };

  getColumnSearchProps = (dataIndex, label) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${label}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: 'block'}}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined/>}
          size="small"
          style={{width: 90, marginRight: 8}}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({searchText: ''});
  };

  render() {
    const {
      user,
      office_category_id
    } = this.props;

    const columns = [
      // {
      //   title: 'Account Type',
      //   dataIndex: 'account_type_name',
      //   key: 'account_type_name',
      //   width: 100,
      //   sorter: (a, b) => helper.sortData('account_type_name', a, b),
      //   ...this.getColumnSearchProps('account_type_name', 'Account Type'),
      // },
      {
        title: 'Parent Code',
        dataIndex: 'parent_account_code',
        key: 'parent_account_code',
        width: 100,
        sorter: (a, b) => helper.sortData('parent_account_code', a, b),
        ...this.getColumnSearchProps('parent_account_code', 'Parent Account Code'),
      },
      {
        title: 'Parent',
        dataIndex: 'parent_account_name',
        key: 'parent_account_name',
        width: 150,
        ellipsis: true,
        sorter: (a, b) => helper.sortData('parent_account_name', a, b),
        ...this.getColumnSearchProps('parent_account_name', 'Parent Account Name'),
      },
      {
        title: 'Code',
        dataIndex: 'account_code',
        key: 'account_code',
        width: 100,
        sorter: (a, b) => helper.sortData('account_code', a, b),
        ...this.getColumnSearchProps('account_code', 'Account Code'),
      },
      {
        title: 'Name',
        dataIndex: 'account_name',
        key: 'account_name',
        width: 200,
        ellipsis: true,
        sorter: (a, b) => helper.sortData('account_name', a, b),
        ...this.getColumnSearchProps('account_name', 'Name'),
      },
      {
        title: 'Closing Balance',
        dataIndex: 'Closing',
        key: 'Closing',
        width: 200,
        children: [
          {
            title: 'Dr',
            dataIndex: 'closing_dr_amount',
            key: 'closing_dr_amount',
            width: 100,
            align: "right",
            sorter: (a, b) => helper.sortData('closing_dr_amount', a, b),
            ...this.getColumnSearchProps('closing_dr_amount', 'Closing Dr'),
            render: (text, record) => {
              return <div
                style={{textAlign: "right"}}>{record.closing_dr_amount ? helper.toFloatFixed(record.closing_dr_amount) : ""}</div>
            },
          },
          {
            title: 'Cr',
            dataIndex: 'closing_cr_amount',
            key: 'closing_cr_amount',
            width: 100,
            align: "right",
            sorter: (a, b) => helper.sortData('closing_cr_amount', a, b),
            ...this.getColumnSearchProps('closing_cr_amount', 'Closing Cr'),
            render: (text, record) => {
              return <div
                style={{textAlign: "right"}}>{record.closing_cr_amount ? helper.toFloatFixed(record.closing_cr_amount) : ""}</div>
            },
          },
        ]
      },
    ];

    return (
      <React.Fragment>
        <div style={{padding: "15px 20px"}} ref={node => this.main_div = node} tabIndex="-1">
          <Row style={{marginBottom: "8px"}}>
            <Col sm={18}>
              <Typography.Text strong>Trial Balance</Typography.Text>
            </Col>
          </Row>

          <Form
            name="advanced_search"
            className="ant-advanced-search-form"
            style={{marginBottom: "8px"}}
            layout='vertical'
          >
            <Row gutter={24}>
              {/*{office_category_id !== constants.OFFICE_CATEGORY_MART_OFFICE ? <Col xs={24} sm={6} md={5} lg={4}>
                <Form.Item label='Office' style={{marginBottom: "8px"}}>
                  <Select
                    showSearch
                    allowClear
                    style={{width: '100%'}}
                    value={this.state.office_id}
                    placeholder="Select office"
                    optionFilterProp="children"
                    onChange={(value, option) => {
                      this.setState({
                        office_id: value,
                        rows: [],
                        dr_amount_total: 0,
                        cr_amount_total: 0,
                        show_data: false,
                      });
                    }}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.office_list.map((obj, i) => {
                      return (
                        <Select.Option key={obj.office_id}
                                       value={obj.office_id}>{`${obj.office_code} - ${obj.office_name}`}</Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col> : null}*/}

              <Col xs={24} sm={6} md={5} lg={4}>
                <Form.Item label="From Date">
                  <Input allowClear name="from_date" value={this.state.from_date}
                         placeholder="Enter from date"
                         onChange={this.onChange}
                         onPressEnter={() => {
                           this.fetch_report();
                         }}/>
                </Form.Item>
              </Col>

              <Col xs={24} sm={6} md={5} lg={4}>
                <Form.Item label="To Date">
                  <Input allowClear name="to_date" value={this.state.to_date}
                         placeholder="Enter to date"
                         onChange={this.onChange}
                         onPressEnter={() => {
                           this.fetch_report();
                         }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={6} md={5} lg={4}>
                <Form.Item label="Show Zero Balance?">
                  <Checkbox checked={this.state.show_zero_balance} onChange={(e) => {
                    this.setState({show_zero_balance: !this.state.show_zero_balance})
                  }}/>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24} style={{textAlign: "right"}}>
                <Form.Item label="" style={{marginTop: "8px"}}>
                  <Button type="primary" style={{marginRight: "8px"}} onClick={(e) => {
                    this.fetch_report();
                  }} disabled={this.state.is_report_loading} loading={this.state.is_report_loading}>View Report</Button>

                  <Button title="Export to excel" type="default" onClick={(e) => {
                    this.export_report_excel();
                  }} disabled={this.state.is_exporting_excel} loading={this.state.is_exporting_excel}
                          icon={<FileExcelOutlined/>}>Excel</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Card style={{marginBottom: "8px"}} size="small">
            {this.state.show_data &&
            <Table rowKey="account_id" columns={columns} size='medium' dataSource={this.state.rows}
                   loading={this.state.is_report_loading}
                   scroll={{x: 800}}
                   bordered
                   style={{marginBottom: "8px"}}
                   className="sa-table-sm"
                   showSorterTooltip={false}
                   pagination={{
                     showSizeChanger: true, showTotal: (total) => {
                       return `Total ${total} records`;
                     }
                   }}
                   summary={pageData => {
                     return (
                       <>
                         <Table.Summary.Row>
                           <Table.Summary.Cell colSpan={4} className="text-right">Total</Table.Summary.Cell>
{/*

                           <Table.Summary.Cell className="text-right">
                             {helper.toFloatFixed(this.state.opening_dr_total)}
                           </Table.Summary.Cell>

                           <Table.Summary.Cell className="text-right">
                             {helper.toFloatFixed(this.state.opening_cr_total)}
                           </Table.Summary.Cell>

                           <Table.Summary.Cell className="text-right">
                             {helper.toFloatFixed(this.state.current_dr_total)}
                           </Table.Summary.Cell>

                           <Table.Summary.Cell className="text-right">
                             {helper.toFloatFixed(this.state.current_cr_total)}
                           </Table.Summary.Cell>
*/}

                           <Table.Summary.Cell className="text-right">
                             {helper.toFloatFixed(this.state.closing_dr_total)}
                           </Table.Summary.Cell>

                           <Table.Summary.Cell className="text-right">
                             {helper.toFloatFixed(this.state.closing_cr_total)}
                           </Table.Summary.Cell>
                         </Table.Summary.Row>

                         <Table.Summary.Row>
                           <Table.Summary.Cell colSpan={4} className="text-right">Diff</Table.Summary.Cell>
{/*

                           <Table.Summary.Cell className="text-right">
                             {helper.toFloatFixed(this.state.opening_dr_total - this.state.opening_cr_total)}
                           </Table.Summary.Cell>

                           <Table.Summary.Cell />

                           <Table.Summary.Cell className="text-right">
                             {helper.toFloatFixed(this.state.current_dr_total - this.state.current_cr_total)}
                           </Table.Summary.Cell>
*/}

                           {/*<Table.Summary.Cell />*/}

                           <Table.Summary.Cell className="text-right">
                             {helper.toFloatFixed(this.state.closing_dr_total-this.state.closing_cr_total)}
                           </Table.Summary.Cell>

                           <Table.Summary.Cell />
                         </Table.Summary.Row>
                       </>
                     );
                   }}
            />}
          </Card>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  staff: state.user.staff,
  office_category_id: state.user.staff.office_category_id,
  head_office_list: state.user.head_office_list,
  mart_office_list: state.user.mart_office_list,
  active_fiscal_year: state.user.fiscal_year,
  day_closure_bs_date: state.user.day_closure_bs_date,
});

export default connect(mapStateToProps)(TrialBalanceReport3);
