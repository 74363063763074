// localhost
export const LOCAL_API_BASE_URL = 'http://localhost:8000/api';
// export const LOCAL_API_BASE_URL = 'http://192.168.1.125:8000/api';

// local development server
export const LOCAL_DEV_API_BASE_URL = 'http://localhost:8000/api';

// remote development server
export const REMOTE_DEV_API_BASE_URL = 'https://demo.amahimart.com/api';

// remote production server
export const REMOTE_PROD_API_BASE_URL = 'https://mart.amahimart.com/api';

export const API_USER_AGENT = 'JBS-AMAHI-MART';

export const DEFAULT_PAGE_SIZE = 10;

export const PAGINATION_PAGE_OPTIONS = ['5', '10', '20', '50', '100'];

export const USER_TYPE_AMAHI_MART = 'amahimart';
export const USER_TYPE_JBL = 'jbl';

export const OFFICE_CATEGORY_HEAD_OFFICE = 1;
export const OFFICE_CATEGORY_MART_OFFICE = 4;
export const OFFICE_CATEGORY_BRANCH_OFFICE = 4;
export const OFFICE_CATEGORY_JBLBSL_OFFICE = 6;
export const OFFICE_CATEGORY_ID_HO_AND_MART = `${OFFICE_CATEGORY_HEAD_OFFICE},${OFFICE_CATEGORY_MART_OFFICE}`;

export const JBL_OFFICE_CATEGORY_HO = 0
export const JBL_OFFICE_CATEGORY_PROVINCE = 1
export const JBL_OFFICE_CATEGORY_DISTRICT = 2
export const JBL_OFFICE_CATEGORY_BRANCH = 3


export const PAY_TYPE_CODE_CASH_BANK = 'CB';
export const PAY_TYPE_CODE_IBT = 'IBT';
export const PAY_TYPE_CODE_CASH_BANK_INCENTIVE = 'CBI';


export const CUSTOMER_CATEGORY_ID_NORMAL_CUSTOMER = 1;
export const CUSTOMER_CATEGORY_ID_STAFF = 2;
export const CUSTOMER_CATEGORY_ID_JBL_MEMBER = 3;

export const SALE_TYPE_MEMBER = 'Member';
export const SALE_TYPE_STAFF = 'Staff';
export const SALE_TYPE_NORMAL = 'Normal';

export const SALE_PRICE_TYPE_RETAIL = 'Retail';
export const SALE_PRICE_TYPE_WHOLESALE = 'Wholesale';
export const SALE_PRICE_TYPE_STAFF = 'Staff';

export const RECEIPT_TYPE_MEMBER = 'Member';
export const RECEIPT_TYPE_OTHER = 'Other';

export const PAYMENT_TYPE_OTHER = 'Other';
export const PAYMENT_TYPE_MEMBER = 'Member';

export const ACCOUNT_GROUP_CODE_MEMBER = 'M';
export const ACCOUNT_GROUP_CODE_CUSTOMER = 'C';
export const ACCOUNT_GROUP_CODE_SUPPLIER = 'S';
export const ACCOUNT_GROUP_CODE_CUSTOMER_SUPPLIER = 'CS';

export const SALE_RETURN_TYPE_MEMBER = 'Member';

export const ACCOUNT_TYPE_ASSET = 1;
export const ACCOUNT_TYPE_LIABILITY = 2;
export const ACCOUNT_TYPE_INCOME = 3;
export const ACCOUNT_TYPE_EXPENSE = 4;

export const CLIENT_JBL_OFFICE_CATEGORY_BRANCH_OFFICE = 3
