import React, {Component} from 'react';
import {connect} from 'react-redux';
import {apiInstance} from '../../services/api';
import * as user_service from "../../services/user_service";
import {history} from "../../history";
import {Layout, Card, Input, Form, Row, Col, Button, Alert} from 'antd';
import {handle_response_error} from "../../utils/helper";
import * as user_actions from "../../actions/user_actions";
import store from "../../store";
import {FETCH_CURRENT_USER_ERROR, FETCH_CURRENT_USER_START, FETCH_CURRENT_USER_STOP} from "../../actions/types";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import logo_sm_url from '../../assets/img/logo_sm.jpg';

const {Content} = Layout;

class Login extends Component {
  is_mounted = null;

  state = {
    is_loading: false,
    error_message: null,
    username: '',
    password: '',
  };

  componentDidMount() {
    if (localStorage.token) {
      history.push('/');
      return;
    }

    this.is_mounted = true;
    this.username_input.focus();

    // this.setState({
    //   username: 'S001',
    //   password: '9842397364',
    // })
  }

  componentWillUnmount() {
    this.is_mounted = false;
  }

  go_to_dashboard = () => {
    history.push('/');
  };

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  onSubmit = (e) => {
    e.preventDefault();

    const payload = {
      username: this.state.username,
      password: this.state.password
    };

    this.setState({error_message: false, is_loading: true});

    user_service.login(payload).then(res => {
      if (this.is_mounted) {
        if (res.data) {
          const token = res.data.token;

          store.dispatch(user_actions.set_token(token));

          apiInstance.defaults.headers['Authorization'] = `Token ${token}`;
          localStorage.setItem('token', token);

          store.dispatch({type: FETCH_CURRENT_USER_START});
          user_service.get_current_user().then(res => {
            let data = res.data;

            store.dispatch(user_actions.set_current_user(data));
            store.dispatch({type: FETCH_CURRENT_USER_STOP});
            history.push('/');
          }).catch(err => {
            store.dispatch({type: FETCH_CURRENT_USER_ERROR});
          });
        }
      }
    }).catch(err => {
      if (this.is_mounted) {
        let error_message = handle_response_error(err);
        this.setState({is_loading: false, error_message: error_message});
      }
    });
  };

  render() {
    return (
      <Layout style={{minHeight: '100vh'}}>
        <Content className="san-content">
          <Row type="flex" justify="center" align="middle" style={{height: "100vh"}}>
            <Col xxl={5} xl={7} lg={6} md={7} sm={7}>
              <div style={{textAlign:"center", marginBottom: "8px"}}>
                <img src={logo_sm_url} alt="Amahi Mart" style={{height: "100px"}}/>
              </div>

              {this.state.error_message &&
                <Alert message={this.state.error_message} type="error" closable showIcon style={{marginBottom: "8px"}}/>}

              <Card bodyStyle={{padding: "16px"}} style={{boxShadow: "0 1rem 3rem rgba(0,0,0,.175)"}}>
                <Form layout="vertical">
                  <Form.Item label="Username" style={{marginBottom: "8px"}}>
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Enter username"
                      ref={node => this.username_input = node}
                      value={this.state.username}
                      name="username"
                      onChange={this.onChange}
                      onPressEnter={() => this.password_input.focus()}
                    />
                  </Form.Item>

                  <Form.Item label="Password" style={{marginBottom: "8px"}}>
                    <Input.Password
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Enter password"
                      ref={node => this.password_input = node}
                      value={this.state.password}
                      name="password"
                      onChange={this.onChange}
                      onPressEnter={(e) => this.onSubmit(e)}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" block={true} onClick={this.onSubmit} disabled={this.state.is_loading} loading={this.state.is_loading}>Login</Button>
                  </Form.Item>
                </Form>

              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(Login);
