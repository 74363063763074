import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import OverlaySpinner from "../overlay-spinner/OverlaySpinner";
import {Modal} from "antd";

const PrivateRoute = ({component: Component, user, ...rest}) => (
  <Route
    {...rest}
    render={props => {
      if (!localStorage.token) {
        return <Redirect to='/login'/>
      } else {
        if (user.is_fetching_user) {
          return <OverlaySpinner message="Please wait..."/>
        } else if (user.user_fetch_error) {
          Modal.error({centered: true, content: "Unable to login"});
          return null;
        } else {
          return <Component {...props} />
        }
      }
      // return <Component {...props} />
    }}
  />
);

PrivateRoute.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(PrivateRoute);
