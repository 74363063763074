import adbs from "ad-bs-converter";
// import adbs from "../libs/ad-bs-converter/ad_bs_converter";
import moment from "moment";
import {apiInstance} from "../services/api";
import store from '../store';
import * as user_actions from '../actions/user_actions';
import * as constants from "./constants";

export const handle_response_error = (err) => {
  let message = null;

  if (err.response && err.response.status === 401 && err.response.data.message === 'Invalid token.') {
    store.dispatch(user_actions.user_logout());
    return;
  }

  if (err.response && err.response.status === 404) {
    if (err.response.data && err.response.data.message) {
      return err.response.data.message
    } else {
      return 'Not found'
    }
  }

  if (err.response && err.response.data) {
    if (err.response.status === 405) {
      message = "Method Not Allowed.";
    } else {
      let data = err.response.data;
      if (data.message) {
        message = data.message;
      } else if (data.detail) {
        message = data.detail;
      } else {
        message = "Unknown error occurred.";
      }
    }
  } else {
    message = "Error in server connection.";
  }

  return message;
};

export const getWebSocketURL = () => {
  let websocket_url = apiInstance.defaults.baseURL
  if (websocket_url.includes('https://')) {
    websocket_url = websocket_url.replace("https://", "wss://");
  } else {
    websocket_url = websocket_url.replace("http://", "ws://");
  }
  websocket_url = websocket_url.replace("/api", "");
  return websocket_url;
};

export const addYear = (bsDate, yearsToAdd) => {
  let adDate = adbs.bs2ad(bsDate);

  adDate = moment(`${adDate.year}/${adDate.month}/${adDate.day}`, "YYYY/MM/DD");
  let newDateBS = adbs.ad2bs(adDate.add(yearsToAdd, 'y').format("YYYY/MM/DD")).en;

  if (newDateBS.month <= 9) newDateBS.month = 0 + "" + newDateBS.month;
  if (newDateBS.day <= 9) newDateBS.day = 0 + "" + newDateBS.day;

  return newDateBS.year + "/" + newDateBS.month + "/" + newDateBS.day;
};

export const toAD = (bsDate) => {
  let adDate = adbs.bs2ad(bsDate);

  if (adDate.month <= 9) adDate.month = 0 + "" + adDate.month;
  if (adDate.day <= 9) adDate.day = 0 + "" + adDate.day;

  return `${adDate.year}-${adDate.month}-${adDate.day}`;
};

export const formatDate = (dt) => {
  return moment(dt).format("YYYY/MM/DD");
};

export const getTodayDate = () => {
  let ad = moment().format("YYYY/MM/DD");

  let bs = adbs.ad2bs(ad).en;

  if (bs.month <= 9) bs.month = 0 + "" + bs.month;
  if (bs.day <= 9) bs.day = 0 + "" + bs.day;

  bs = bs.year + "/" + bs.month + "/" + bs.day;
  return bs
};

export const formatDateTime = (dt) => {
  let dateBS = adbs.ad2bs(formatDate(dt)).en;
  if (dateBS.month <= 9) dateBS.month = 0 + "" + dateBS.month;
  if (dateBS.day <= 9) dateBS.day = 0 + "" + dateBS.day;

  dateBS = dateBS.year + "/" + dateBS.month + "/" + dateBS.day;

  let time = moment(dt).format("h:mm:ss a");

  return `${dateBS} ${time}`;
};

export const getFirstDayOfMonth = (date) => {
  let startDateArr = date.split('/');
  return startDateArr[0] + '/' + startDateArr[1] + '/01';
};

export const numberToText = (value) => {
  let fraction = Math.round(frac(value) * 100);
  let f_text = "";
  if (fraction > 0) {
    f_text = "AND " + convert_number(fraction) + " PAISA";
  }

  return convert_number(value) + " RUPEE " + f_text + " ONLY";
};

const frac = (f) => {
  return f % 1;
};

const convert_number = (number) => {
  if ((number < 0) || (number > 999999999)) {
    return "NUMBER OUT OF RANGE!";
  }

  let Gn = Math.floor(number / 10000000); /* Crore */
  number -= Gn * 10000000;

  let kn = Math.floor(number / 100000); /* lakhs */
  number -= kn * 100000;

  let Hn = Math.floor(number / 1000); /* thousand */
  number -= Hn * 1000;

  let Dn = Math.floor(number / 100); /* Tens (deca) */
  number = number % 100; /* Ones */

  let tn = Math.floor(number / 10);

  let one = Math.floor(number % 10);

  let res = "";

  if (Gn > 0) {
    res += (convert_number(Gn) + " CRORE");
  }

  if (kn > 0) {
    res += (((res === "") ? "" : " ") +
      convert_number(kn) + " LAKH");
  }
  if (Hn > 0) {
    res += (((res === "") ? "" : " ") +
      convert_number(Hn) + " THOUSAND");
  }

  if (Dn) {
    res += (((res === "") ? "" : " ") +
      convert_number(Dn) + " HUNDRED");
  }

  let ones = ["", "ONE", "TWO", "THREE", "FOUR", "FIVE", "SIX", "SEVEN", "EIGHT", "NINE", "TEN", "ELEVEN", "TWELVE", "THIRTEEN", "FOURTEEN", "FIFTEEN", "SIXTEEN", "SEVENTEEN", "EIGHTEEN", "NINETEEN"];
  let tens = ["", "", "TWENTY", "THIRTY", "FORTY", "FIFTY", "SIXTY", "SEVENTY", "EIGHTY", "NINETY"];
  if (tn > 0 || one > 0) {
    if (!(res === "")) {
      res += " AND ";
    }

    if (tn < 2) {
      res += ones[tn * 10 + one];
    } else {
      res += tens[tn];
      if (one > 0) {
        res += ("-" + ones[one]);
      }
    }
  }

  if (res === "") {
    res = "ZERO";
  }
  return res;
};

export const downloadFile = (data, fileName) => {
  window.URL = window.URL || window.webkitURL;

  const url = window.URL.createObjectURL(new Blob([data]));
  const a = document.createElement('a');
  a.href = url;
  a.setAttribute('download', fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(a.href);
};

export const exportToExcel = (data, fileName) => {
  window.URL = window.URL || window.webkitURL;

  const url = window.URL.createObjectURL(new Blob([data]));
  const a = document.createElement('a');
  a.href = url;
  a.setAttribute('download', fileName + '.xlsx');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(a.href);
};

export const exportToCSV = (data, fileName, showHeader = true) => {
  window.URL = window.URL || window.webkitURL;

  let dataArr = data;

  let csv = '';

  if (showHeader && dataArr.length > 0) {
    let row = "";

    for (let key in dataArr[0]) {
      row += `"${key}",`;
    }

    row = row.slice(0, -1);
    csv += row + '\n';
  }

  dataArr.forEach((obj, idx) => {
    let row = "";
    for (let key in obj) {
      row += `"${obj[key]}",`;
    }
    row = row.slice(0, -1);
    row += "\n";
    csv += row;
  });

  let blob = new Blob([csv], {type: "text/csv"});
  let a = window.document.createElement("a");
  a.href = window.URL.createObjectURL(blob);
  a.download = fileName + ".csv";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(a.href);
};

export const toCurrency = (amount, style=false) => {
  if (style) {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'NPR',
    }).format(amount).replace("NPR", '');
  } else {
    return new Intl.NumberFormat('en-IN').format(amount);
  }
};

export const getNepaliMonthsArr = () => {
  return [
    {
      id: 4,
      name: 'Shrawan'
    },
    {
      id: 5,
      name: 'Bhadra'
    },
    {
      id: 6,
      name: 'Ashoj'
    },
    {
      id: 7,
      name: 'Kartik'
    },
    {
      id: 8,
      name: 'Mangsir'
    },
    {
      id: 9,
      name: 'Poush'
    },
    {
      id: 10,
      name: 'Magh'
    },
    {
      id: 11,
      name: 'Falgun'
    },
    {
      id: 12,
      name: 'Chaitra'
    },
    {
      id: 1,
      name: 'Baishak'
    },
    {
      id: 2,
      name: 'Jestha'
    },
    {
      id: 3,
      name: 'Ashad'
    },
  ];
};

export const get_office_data = (head_office_also = false) => {
  let state = store.getState();

  let user = state.user;
  let office_category_id = state.user.office_category_id;

  let office_id = undefined;
  let report_type_list = [];
  let head_office_list = [];
  let province_office_list = [];
  let district_office_list = [];
  let mart_office_list = [];
  let jblbsl_office_list = [];

  if (user.report_type_list) {
    report_type_list = user.report_type_list
  }

  if (head_office_also) {
    report_type_list.unshift({
      id: 1,
      code: 'head_office_wise',
      name: 'Head Office'
    });
  }

  if (user.head_office_list) {
    head_office_list = user.head_office_list
  }

  if (user.province_office_list) {
    province_office_list = user.province_office_list
  }

  if (user.district_office_list) {
    district_office_list = user.district_office_list
  }

  if (user.mart_office_list) {
    mart_office_list = user.mart_office_list
  }

  if (user.jblbsl_office_list) {
    jblbsl_office_list = user.jblbsl_office_list
  }

  let data = {
    office_category_id: office_category_id,
    user: user,
    report_type_list: report_type_list,
    // report_type: constants.DEFAULT_REPORT_TYPE,
    province_office_list: province_office_list,
    district_office_list: district_office_list,
    mart_office_list: mart_office_list,
    jblbsl_office_list: jblbsl_office_list,
    office_list: mart_office_list,
    branch_office_dict: user.branch_office_dict,
    office_id: office_id
  };

  if (head_office_also) {
    data.head_office_list = head_office_list;
  }
  return data
};

export const sortData = (key, a, b) => {
  if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;

  const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
  const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

  if (varA < varB) {
    return -1
  }

  if (varA > varB) {
    return 1
  }

  return 0
};

export const get_base_url = () => {
  // return apiInstance.defaults.baseURL.replace('/api', '');
  return apiInstance.defaults.baseURL;
};

export const is_valid_number = (value) => {
  const reg = /^-?\d*(\.\d*)?$/;
  return (!isNaN(value) && reg.test(value)) || value === '' || value === '-';
}

export const is_valid_positive_number = (value) => {
  const reg = /^-?\d*(\.\d*)?$/;
  return (!isNaN(value) && reg.test(value)) || value === '' || value === '-';
}

export const has_role = (roles, slug) => {
  // let state = store.getState();
  // const roles = state.user.roles ? state.user.roles : [];
  return roles.includes(slug);
};

export const has_permission = (permissions, slug) => {
  return permissions.includes(slug);
};

export const is_head_office_staff = (staff) => {
  return staff.office_category_id === constants.OFFICE_CATEGORY_HEAD_OFFICE;
};

export const is_mart_office_staff = (staff) => {
  return staff.office_category_id === constants.OFFICE_CATEGORY_MART_OFFICE;
};

export const is_branch_office_staff = (staff) => {
  return staff.office_category_id === constants.OFFICE_CATEGORY_BRANCH_OFFICE;
};

export const toFloatFixed = (amount) => {
  return parseFloat(amount).toFixed(2);
  // return amount;
}

const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}


/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */

export async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const maxSize = Math.max(image.width, image.height)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea
  canvas.height = safeArea

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2)
  ctx.rotate(getRadianAngle(rotation))
  ctx.translate(-safeArea / 2, -safeArea / 2)

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  )
  const data = ctx.getImageData(0, 0, safeArea, safeArea)

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  )

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise(resolve => {
    canvas.toBlob(file => {
      // resolve(URL.createObjectURL(file))
      return resolve(file);
    }, 'image/jpeg')
  });

  // return new Promise(resolve => {
  //   canvas.toBlob(file => {
  //     resolve(URL.createObjectURL(file))
  //   }, 'image/jpeg')
  // });
}
