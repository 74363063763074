import React, { Component } from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import {history} from './history';
import Login from './components/user/Login';
import {connect} from 'react-redux';
import * as user_actions from './actions/user_actions';
import Mousetrap from 'mousetrap';
import DefaultLayout from "./components/layout/DefaultLayout";
import PrivateRoute from "./components/common/PrivateRoute";
import OverlaySpinner from "./components/overlay-spinner/OverlaySpinner";
import loadable from '@loadable/component';

const SalesInvoiceCreate = loadable(() => import('./components/customer/SalesInvoiceCreate'), {fallback: <OverlaySpinner />})

class App extends Component {
  componentDidMount() {
    this.bindShortCuts();
  }

  componentWillUnmount() {
    this.unBindShortCuts();
  }

  bindShortCuts = () => {
    Mousetrap.bindGlobal('ctrl+shift+/', (e, combo) => {
      e.preventDefault();
      history.push('/');
    });

    Mousetrap.bindGlobal('ctrl+g', (e, combo) => {
      e.preventDefault();
      this.props.dispatch(user_actions.toggleLogoutModal());
    });
  };

  unBindShortCuts = () => {
    Mousetrap.unbind('ctrl+l');
  };

  render() {
    return (
      <React.Fragment>
        <Router history={history}>
          <Switch>
            <Route exact path="/login" component={Login}/>
            <PrivateRoute exact path="/customer/sales_invoice/create/" component={SalesInvoiceCreate}/>
            <Route path="/" component={DefaultLayout}/>
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  showLogoutModal: state.user.showLogoutModal,
});

export default connect(mapStateToProps)(App);
