export const HEADER_SET_MENU = 'HEADER_SET_MENU';
export const HEADER_TOGGLE_FULL_SCREEN = 'HEADER_TOGGLE_FULL_SCREEN';
export const HEADER_HIDE_SIDEBAR = 'HEADER_HIDE_SIDEBAR';

export const USER_LOGIN = 'USER_LOGIN ';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SET_DAYEND_DATE = 'SET_DAYEND_DATE';
export const USER_TOGGLE_LOGOUT_MODAL = 'USER_TOGGLE_LOGOUT_MODAL';
export const USER_PASSWORD_CHANGE_MODAL = 'USER_PASSWORD_CHANGE_MODAL';


export const FETCH_CURRENT_USER_START = 'FETCH_CURRENT_USER_START';
export const FETCH_CURRENT_USER_STOP = 'FETCH_CURRENT_USER_STOP';
export const FETCH_CURRENT_USER_ERROR = 'FETCH_CURRENT_USER_ERROR';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const SET_TOKEN = 'SET_TOKEN';

export const TOGGLE_NOTICE_MODAL = 'TOGGLE_NOTICE_MODAL';

